import React, { useEffect } from 'react'
import { resolveFormByName } from '../../config/hubspot-forms'
import HubspotModalContainer from '../../library/HubspotModalLauncher/HubspotModalContainer'
import HubspotModalLauncher from '../../library/HubspotModalLauncher/HubspotModalLauncher'
import SEO from './SEO'

export default function MainLayout({seo, children}) {
    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        console.log(params)
        if(params.open_popup !== undefined){
            const formSettings = resolveFormByName('contact_form')
            HubspotModalLauncher.open(formSettings)
        }
    }, [])

    return <React.Fragment>
        {seo && <SEO {...seo}></SEO>}
        <main>{ children }</main>
        <HubspotModalContainer/>
    </React.Fragment>
}
