import React from 'react'
import { Button, Heading, Image } from '../../components'
import background from '../../assets/images/contact-background.png'

export default function Contact({title}) {
    return <section className="bg-secondary">
        <Image src={background}></Image>
        <div className="md:px-10 lg:px-20 2xl:px-40 2xl:py-20 py-20 text-center">
            <Heading h1 className="text-white text-3xl3 md:text-5xl4 2xl:text-7xl3">{ title }</Heading>
            <Button primary className="mt-8" link="hubspot:contact_form">¡CONTÁCTANOS!</Button>
        </div>
    </section>
}
