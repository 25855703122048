import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import favicon from '../../../static/favicon.png';
import SchemaOrg from './SchemaOrg'
import { withPrefix } from 'gatsby'

const Schema = ({schemas = []}) => {
    const schemaInit = [
        {
            "@context":"https://schema.org",
            "@type":"Organization",
            "name":"Alanube",
            "telephone": "829 956 0059",
            "url":"https://www.alanube.do/",
            "email":"soporte@alanube.do",
            "contactPoint":[
                {
                    "@type":"ContactPoint",
                    "contactType":"customer service",
                    "email":"soporte@alanube.do",
                    "availableLanguage":"es"
                }
            ],
            "brand":{
                "@type":"Organization",
                "name":"Alanube"
            }
        }
    ]

    if(schemas.length){
        schemas.forEach(schema => {
            const objectSchema = typeof schema === 'object' ? schema : typeof schema === 'string' ? JSON.parse(schema) : null

            if(objectSchema){
                schemaInit.push(objectSchema)
            }
        });
    }

    return (
        <Helmet>
            {/* Schema.org tags */}
            <script type="application/ld+json">{JSON.stringify(schemaInit)}</script>
        </Helmet>
    );
}

function SEO({ title, description, canonical, metatags: meta, lang, schemas }) {
    const path = typeof window === "undefined"  ? '' : window.location.pathname
    const url = typeof window === "undefined"  ? '' : window.location.href

    return (
        <React.Fragment>
            <Helmet defer={false} title={title} titleTemplate={`${title}`}>
                <link href={favicon} rel="shortcut icon" type="image/png"/>

                {/* Meta tags */}
                <meta name="title" content={title} />
                <meta name="description" content={description} />
                {meta && <meta name="language" content={meta.language} />}
                {/*<meta name="author" content="Soluciones Alegra" />*/}
                <meta name="robots" content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"/>
            
                {/* OpenGraph tags */}
                <meta property="og:url" content={url} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                {/*<meta property="og:image" content="https://cdn2.alegra.com/website/imagen-facebook.png" />*/}
                <meta property="og:site_name" content="Alanube" />
                {/* <meta property="fb:app_id" content={seo.social.fbAppID} /> */}
            
                {/* Twitter Card tags */}
                <meta name="twitter:card" content="summary" />
                {/*<meta name="twitter:site" content="@alegraweb" />*/}
                {/*<meta name="twitter:creator" content="@alegraweb" />*/}
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={description} />
                {/*<meta name="twitter:image" content="https://cdn2.alegra.com/website/imagen-facebook.png" />*/}
                
                {/* Etiquetas de idioma y ubicación */}
                <html lang={lang}/>

                {canonical && <link href={canonical} rel="canonical"></link>}
                <script src="https://js.hscta.net/cta/current.js"></script>
            </Helmet>

            <Schema schemas={schemas && schemas.schemas ? schemas.schemas : []}/>
        </React.Fragment>
    )
}

SEO.defaultProps = {
    lang: `es`,
    metatags: {},
    description: ``,
    title: '',
    keywords: '',
    language: 'Spanish',
}

SEO.propTypes = {
    lang: PropTypes.string,
    metatags: PropTypes.object,
    description: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    keywords: PropTypes.string,
    language: PropTypes.string
}

export default SEO