import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import HubspotModalLauncher from './HubspotModalLauncher';
import { Modal } from '../../components';
import HubspotForm from 'react-hubspot-form'

const HubspotModalContainer = () => {
    const [hubspotSettings, setHubspotSettings] = useState({})

    const [isOpen, setIsOpen] = useState(false)

    const handleOpen = (hsSettings) => {
        setHubspotSettings(hsSettings)
        setIsOpen(true)
    }

    const handleClose = () => {
        setIsOpen(false)
        setHubspotSettings({})
    }

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://js.hsforms.net/forms/v2.js';
        document.body.appendChild(script);
        
        HubspotModalLauncher.addOpenListener(handleOpen)
        HubspotModalLauncher.addCloseListener(handleClose)

        return () => {
            HubspotModalLauncher.removeOpenListener(handleOpen)
            HubspotModalLauncher.removeCloseListener(handleClose)
        }
    }, [])

    return <Modal isOpen={isOpen} onClose={handleClose} id={hubspotSettings.id}>
        {isOpen && <HubspotForm
            portalId={hubspotSettings.portalId}
            formId={hubspotSettings.formId}
            onSubmit={() => {
                if(hubspotSettings.onSubmit){
                    hubspotSettings.onSubmit()
                }else{
                    HubspotModalLauncher.close()
                }
            }}
            onReady={(form) => {}}
            loading={<div>Loading...</div>}
            />}
    </Modal>
}

export default HubspotModalContainer;